import coverSrc from 'assets/img/generic/4.jpg';
import apple from 'assets/img/logos/apple.png';
import google from 'assets/img/logos/g.png';
import hp from 'assets/img/logos/hp.png';
import VerifiedBadge from 'components/common/VerifiedBadge';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileBanner from '../ProfileBanner';

const Banner = user => {
  const [rightSidedItems] = useState([
    {
      title: 'Google',
      image: google
    },
    {
      title: 'Apple',
      image: apple
    },
    {
      title: ' Hewlett Packard',
      image: hp
    }
  ]);

  return (
    <ProfileBanner>
      {/* TODO: #12 Beef up the profile with more relavent components */}
      <ProfileBanner.Header coverSrc={coverSrc} user={user} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {user.firstName} {user.lastName} <VerifiedBadge />
            </h4>
            {/* TODO: #11 Add edit capability to role and location */}
            {/* <h5 className="fs-9 fw-normal">
              
              Senior Software Engineer at Technext Limited
              </h5>
              <p className="text-500">New York, USA</p>
              <Button variant="falcon-primary" size="sm" className="px-3">
              Following
              </Button>
              <Button variant="falcon-default" size="sm" className="px-3 ms-2">
              Message
            </Button> */}
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {/* <Link to="#!" className="link-900">
              <Flex alignItems="center" className="mb-2">
              <FontAwesomeIcon
              icon="user-circle"
              className="me-2 text-700"
              style={{ height: '30px', width: '30px' }}
              />
              <div className="flex-1">
              <h6 className="mb-0">See followers (330)</h6>
              </div>
              </Flex>
              </Link>
              
              {rightSidedItems.map(item => (
                <Link to="#!" key={item.title} className="link-900">
                <Flex alignItems="center" className="mb-2">
                <img
                src={item.image}
                alt={item.title}
                width={30}
                className="me-2"
                />
                <div className="flex-1">
                <h6 className="mb-0">{item.title}</h6>
                </div>
                </Flex>
                </Link>
              ))} */}
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
