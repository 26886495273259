import Avatar from 'components/common/Avatar';
import { useUser } from 'context/UserProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProfileDropdown = () => {
  const { user, setUser } = useUser();

  const logout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  ProfileDropdown.propTypes = {
    user: PropTypes.any,
    firstName: PropTypes.string
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar name={user.firstName} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          {/* TODO: #14 Create a Feedback Form */}
          {/* <Dropdown.Item href="#!">Feedback</Dropdown.Item> */}
          <Dropdown.Divider />
          {/* TODO: #15 Merge with Profile to create edit.  Settings should include password change */}
          {/* <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item> */}
          <Dropdown.Item
            as={Link}
            to="/authentication/card/logout"
            onClick={logout}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
