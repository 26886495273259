import Login from 'components/authentication/simple/Login';
import Registration from 'components/authentication/simple/Registration';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
import { useUser } from 'context/UserProvider';
import React, { useState } from 'react';
import { Card, Dropdown, Modal, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LandingRightSideNavItem = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const { user } = useUser();
  return (
    <Nav navbar className="ms-auto align-items-lg-center">
      <Nav.Item as={'li'} className="me-2 d-none d-lg-block">
        <ThemeControlDropdown iconClassName="fs-10" />
      </Nav.Item>
      {user !== null ? (
        <ProfileDropdown />
      ) : (
        <>
          <Dropdown>
            <Dropdown.Toggle as={Link} to="#!" className="nav-link fw-semibold">
              Login
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-end dropdown-menu-card mt-0 dropdown-caret dropdown-caret-bg">
              <Card className="navbar-card-login shadow-none">
                <Card.Body className="fs-10 fw-normal p-4">
                  <Login />
                </Card.Body>
              </Card>
            </Dropdown.Menu>
          </Dropdown>

          <Nav.Item>
            <Nav.Link
              as={Link}
              to="#!"
              onClick={() => setShowRegistrationModal(!showRegistrationModal)}
            >
              Register
            </Nav.Link>
            <Modal
              show={showRegistrationModal}
              centered
              onHide={() => setShowRegistrationModal(false)}
            >
              <Modal.Body className="p-0">
                <Card>
                  <Card.Body className="fs-10 fw-normal p-4">
                    <Registration />
                  </Card.Body>
                </Card>
              </Modal.Body>
            </Modal>
          </Nav.Item>
        </>
      )}
    </Nav>
  );
};

export default LandingRightSideNavItem;
