import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SpotifyArtist from './SpotifyArtist';
import SpotifyHeader from './SpotifyHeader';
import SpotifyPlayer from './SpotifyPlayer';
import SpotifySearch from './SpotifySearch';
import SpotifyTopItems from './SpotifyTopItems';

const SpotifyApp = user => {
  const [activeMode, setActiveMode] = useState('search');
  const [artist, setArtist] = useState();

  const renderContent = () => {
    switch (activeMode) {
      case 'search':
        return (
          <SpotifySearch setActiveMode={setActiveMode} setArtist={setArtist} />
        );
      case 'artist':
        return <SpotifyArtist setActiveMode={setActiveMode} artist={artist} />;
      default:
        return (
          <SpotifySearch setActiveMode={setActiveMode} setArtist={setArtist} />
        );
    }
  };

  return (
    <>
      <SpotifyHeader user={user} />
      <Row>
        <Col xs="12" lg="8">
          {renderContent()}
        </Col>
        <Col>
          <SpotifyPlayer setActiveMode={setActiveMode} setArtist={setArtist} />
          <SpotifyTopItems
            setActiveMode={setActiveMode}
            setArtist={setArtist}
          />
        </Col>
      </Row>
    </>
  );
};

export default SpotifyApp;
