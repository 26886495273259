import useAxiosPrivateSpotify from 'hooks/useAxiosPrivateSpotify';
import React, { useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import SearchResults from './SearchResults';

const SpotifySearch = () => {
  const { response, error, loading, fetchData } = useAxiosPrivateSpotify();
  const [searchQuery, setSearchQuery] = useState('');
  const checkBtn = useRef();
  const form = useRef();

  const handleSearch = e => {
    e.preventDefault();

    const spacesRemovedSearchQuery = searchQuery.replace(/\s+/g, '+');

    fetchData({
      url: `search?q=${spacesRemovedSearchQuery}&type=artist&market=US`,
      method: 'GET'
    });
  };

  const handleInputChange = e => {
    setSearchQuery(e.target.value);
  };

  const [options] = useState(['Artists', 'Tracks', 'Albums', 'Other']);
  return (
    <Card className="mb-3">
      <Card.Header>
        <Form onSubmit={handleSearch} ref={form}>
          <Row className="g-2">
            <Col>
              <Form.Control
                placeholder="Search..."
                size="sm"
                value={searchQuery}
                onChange={handleInputChange}
                type="text"
                name="searchQuery"
              />
            </Col>
            <Col className="d-md-block d-none text-end" sm="auto">
              <Form.Select size="sm">
                <option value="">Search All</option>
                {options.map(option => (
                  <option key={option}>{option}</option>
                ))}
              </Form.Select>
            </Col>
            <Col className="d-md-block d-none text-end" sm="auto">
              <Button
                variant="primary"
                className="me-2 mb-1"
                type="submit"
                size="sm"
                ref={checkBtn}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Header>
      <Card.Body>
        {response ? <SearchResults results={response} /> : null}
      </Card.Body>
      <Card.Footer>
        <div>
          Results:
          {response === null ? 0 : response.artists.total}
        </div>
      </Card.Footer>
    </Card>
  );
};

export default SpotifySearch;
