import useAxiosWordpress from 'hooks/useAxiosWordpress';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Blog = () => {
  const { response, fetchData } = useAxiosWordpress();

  useEffect(() => {
    fetchData({ url: '/posts', method: 'GET' });
  }, []);

  return (
    <>
      <div>{response?.found}</div>
      <Row>
        <Col xs={2}></Col>
        <Col>
          <Card className="px-5">
            <Card.Header>
              <h1>{response?.posts[0].title}</h1>
            </Card.Header>
            <Card.Body className="px-4">
              <Card.Text
                dangerouslySetInnerHTML={{ __html: response?.posts[0].content }}
              ></Card.Text>
            </Card.Body>
            <Card.Footer></Card.Footer>
          </Card>
        </Col>
        <Col xs={2}></Col>
      </Row>
    </>
  );
};

export default Blog;
