import useAxiosPrivateSpotify from 'hooks/useAxiosPrivateSpotify';
import React, { useEffect } from 'react';

const SpotifyArtist = ({ artist }) => {
  const { response, fetchData } = useAxiosPrivateSpotify();

  useEffect(() => {
    fetchData({
      url: `artists/${artist}`,
      method: 'get'
    });
  }, []);

  console.log(response);

  return (
    <>
      <div>SpotifyArtist</div>
      <div>{response?.name}</div>
    </>
  );
};

export default SpotifyArtist;
