import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

const ProfileIntro = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Card className="mb-3">
      <Card.Header className="bg-body-tertiary">
        <h5 className="mb-0">Intro</h5>
      </Card.Header>

      {/* TODO: #13 Make the intro editable */}

      <Card.Body className="text-1000 p-3">
        <p>
          I’ve been working in software delivery for almost two decades. I’ve
          had the pleasure of working with some amazing companies, leaders, and
          teams in those years. For the past 12 years I’ve led teams, turning
          big ideas into reality and hoping to make business strategy, product
          strategy, technology strategy, and customer needs all play nice
          together. Teamwork, empowerment, and measurement are my key
          ingredients for building strong teams.
        </p>
        <p>
          Outside the office, I’m an avid mountain biker. It can be a wild ride,
          filled with its literal ups and downs just like my professional life –
          tackling the unknown, handling obstacles, and going for success with
          serious grit. This love for adventure and taking calculated risks
          mirrors how I navigate all aspects of my life.
        </p>
        <p>
          Family is a big deal for me. I’ve got two little ones, and I get the
          importance of balance. Just like I guide product teams, I’m guiding my
          kids through life’s adventures, teaching them values like resilience,
          curiosity, and empathy. I also coach their hockey teams (possibly on
          the brink of becoming that crazy hockey parent), bringing my
          leadership experience to the ice to help them grow both on and off the
          rink.
        </p>
        <p>
          I’ve figured out that being a successful leader isn’t just about
          breaking new ground. It’s also about investing in the next wave of
          leaders, building solid relationships, mixing passion with
          practicality, and facing challenges with serious commitment. I’m ready
          to keep making awesome products, exploring new trails, and soaking up
          every free moment with my family.
        </p>
      </Card.Body>

      <Card.Footer className="bg-body-tertiary p-0 border-top d-grid">
        {/* <Button
          variant="link"
          // className="me-2 mb-1"
          onClick={() => setCollapsed(!collapsed)}
        >
          Show {collapsed ? 'less' : 'more'}
          <FontAwesomeIcon
            icon="chevron-down"
            className="ms-2 fs-11"
            transform={collapsed ? 'rotate-180' : ''}
          />
        </Button> */}
      </Card.Footer>
    </Card>
  );
};

export default ProfileIntro;
