import axios from 'axios';
import { useEffect, useState } from 'react';

const useAxiosWordpress = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const axiosInstance = axios.create({
    baseURL:
      'https://public-api.wordpress.com/rest/v1.1/sites/perryoldeenblog.wordpress.com',
    maxBodyLength: Infinity
  });

  useEffect(() => {
    const source = axios.CancelToken.source();

    return () => {
      // Cancel the request when the component unmounts
      source.cancel('Component unmounted: Request cancelled.');
    };
  }, []);

  const fetchData = async ({ url, method, data = {}, params }) => {
    setLoading(true);
    try {
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
        cancelToken: axios.CancelToken.source().token,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setResponse(result.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request cancelled', error.message);
        setError('Request cancelled');
      } else {
        console.log(error);
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };
  return { response, error, loading, fetchData };
};

export default useAxiosWordpress;
