import React from 'react';
import FooterStandard from './FooterStandard';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';

const Landing = () => {
  return (
    <>
      <NavbarStandard />
      <Hero />
      {/*  <Partners />
      <Processes />
      <Services />
      <Testimonial />
      <Cta /> */}
      <FooterStandard />
    </>
  );
};

export default Landing;
