export const bgWhiteIcons = [
  /* { icon: ['fab', 'facebook-f'], color: 'facebook', bg: 'white', href: '#!' },
  { icon: ['fab', 'twitter'], color: 'twitter', bg: 'white', href: '#!' },
  {
    icon: ['fab', 'google-plus-g'],
    color: 'google-plus',
    bg: 'white',
    href: '#!'
  }, */
  {
    icon: ['fab', 'linkedin-in'],
    color: 'linkedin',
    bg: 'white',
    href: 'https://www.linkedin.com/in/perryoldeen/'
  },
  {
    icon: ['fab', 'github'],
    color: 'gray-700',
    bg: 'white',
    href: 'https://github.com/poldeen'
  }
];
export default [
  /* { icon: ['fab', 'facebook-f'], color: 'facebook', href: '#!' },
  { icon: ['fab', 'twitter'], color: 'twitter', href: '#!' },
  {
    icon: ['fab', 'google-plus-g'],
    color: 'google-plus',
    href: '#!'
  }, */
  {
    icon: ['fab', 'linkedin-in'],
    color: 'linkedin',
    href: 'https://www.linkedin.com/in/perryoldeen/'
  },
  {
    icon: ['fab', 'github'],
    color: 'gray-700',
    href: 'https://github.com/poldeen'
  }
];
