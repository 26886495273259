import image from 'assets/img/b2bTeams.png';
import stratProdMgmt from 'assets/img/productStrat/StratProdMgmt.png';
import PageHeader from 'components/common/PageHeader';
import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';

const Product = () => {
  return (
    <>
      <Row>
        <Col xs={2}></Col>
        <Col>
          <PageHeader
            title="Product Managment Philosophy"
            //description="Documentation and examples for how I think about Product Managment"
            className="mb-3"
          >
            {/* <Button
          href={`/docs/components/badge`}
          target="_blank"
          variant="link"
          size="sm"
          className="ps-0"
        >
          Product Managment Philosophy
          <FontAwesomeIcon icon="chevron-right" className="ms-1 fs-11" />
        </Button> */}
          </PageHeader>

          <Card className="px-5">
            <Card.Header></Card.Header>
            <Card.Body className="px-2">
              <Card.Title>Overview</Card.Title>
              <Row>
                <Col>
                  <Card.Text>
                    Product management is not a framework for delivery; it's a
                    comprehensive philosophy that drives the success of a
                    product and its team. At its core lies the empowerment of
                    the team, recognizing that the most impactful results are
                    acheived by the people closest to the problem. Empowering
                    the team involves fostering an environment where every
                    member is encouraged to contribute their unique perspectives
                    and expertise. Digesting a customers problems and outlining
                    them as a list of projects to be delivered by teams
                    constricts innovation, stiffles growth, and reduces the
                    probability of sucessful outcomes. By decentralizing
                    decision-making and embracing diversity, teams can unleash
                    their full potential and tackle challenges with creativity
                    and agility.
                  </Card.Text>
                </Col>
                <Col>
                  <Image src={image} width="425" />
                </Col>
              </Row>

              <Card.Text>
                However, empowerment doesn't equate to a lack of direction. A
                top-down prioritization approach provides the necessary guidance
                and clarity to steer the team towards shared goals and
                objectives. It involves setting clear priorities based on
                strategic objectives, market insights, and customer feedback.
                This approach ensures alignment across the organization and
                prevents the team from getting lost in the multitude of
                possibilities. By focusing on what matters most, teams can
                channel their efforts effectively and maximize their impact.
                Measurement is integral to any successful product management
                philosophy. It involves establishing key performance indicators
                (KPIs) and metrics to track progress, evaluate performance, and
                make data-driven decisions.
              </Card.Text>
              <Card.Text>
                Measurement enables teams to understand what's working, what's
                not, and where adjustments are needed. By continuously
                monitoring and analyzing data, teams can iterate on their
                strategies, optimize their processes, and drive continuous
                improvement. Moreover, measurement fosters accountability and
                transparency, as teams are held accountable for delivering
                results against predefined targets.
              </Card.Text>
              <Card.Text>
                Solving problems lies at the heart of product management. It's
                about identifying customer pain points, market opportunities,
                and technical challenges, and devising innovative solutions to
                address them. Problem-solving requires a deep understanding of
                the customer's needs and preferences, as well as the ability to
                anticipate future trends and disruptions. It involves
                collaborating cross-functionally, leveraging the collective
                expertise of the team, and embracing experimentation and
                iteration. By approaching problems with curiosity, empathy, and
                resilience, teams can overcome obstacles and unlock new
                opportunities for growth and innovation.
              </Card.Text>
              <Card.Text>
                In essence, a holistic product management philosophy combines
                the empowerment of the team, top-down prioritization,
                measurement, and problem-solving to drive the success of a
                product and its team. It's not just about managing projects;
                it's about nurturing a culture of collaboration, accountability,
                and continuous improvement, where every team member is empowered
                to contribute their best work towards a shared vision.
              </Card.Text>
              <Image src={stratProdMgmt} fluid />
            </Card.Body>
            <Card.Footer></Card.Footer>
          </Card>
        </Col>
        <Col xs={2}></Col>
      </Row>
    </>
  );
};

export default Product;
