import axios from 'axios';
import Section from 'components/common/Section';

import PageHeader from 'components/common/PageHeader';
import useAxiosPrivateSpotify from 'hooks/useAxiosPrivateSpotify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import AuthServicePKCE from 'services/spotifyPKCEAuth.service';
import SpotifyApp from './SpotifyApp';
import SpotifyInstructions from './SpotifyInstructions';

const Spotify = () => {
  const { response, error, loading, fetchData } = useAxiosPrivateSpotify();
  const [myParams, setMyParams] = useSearchParams();
  const [user, setUser] = useState();
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const [authStatus, setAuthStatus] = useState('New');

  /* const {
    config: { isDark, isRTL }
  } = useAppContext(); */

  useEffect(() => {
    if (code !== null) {
      setAuthStatus('Verified');
    }

    const token = localStorage.getItem('token');

    if (token !== null && authStatus === 'New') {
      setAuthStatus('Authorized');
    }

    if (authStatus === 'Verified') {
      console.log('trying to fecth token test');
      fetchTokenNew();
      myParams.delete('code');
      setMyParams(myParams);
    }

    if (authStatus === 'Authorized') {
      fetchUser();
    }

    if (authStatus === 'Authenticated') {
      setUser(response);
    }
  }, [authStatus]);

  const fetchTokenNew = async () => {
    try {
      const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
      const verifier = localStorage.getItem('verifier');

      const params = new URLSearchParams();
      params.append('client_id', clientId);
      params.append('grant_type', 'authorization_code');
      params.append('code', code);
      params.append('redirect_uri', 'http://localhost:3000/tech/spotify');
      params.append('code_verifier', verifier);

      console.log("I'm in the fetchTokenNew function");

      const response = await axios.post(
        'https://accounts.spotify.com/api/token',
        params,
        {
          headers: "Content-Type: 'application/x-www-form-urlencoded'"
        }
      );

      localStorage.setItem('token', JSON.stringify(response.data));
      setAuthStatus('Authorized');
    } catch (e) {
      console.log(e);
    }
  };

  const fetchUser = async () => {
    await fetchData({
      url: `me`,
      method: 'GET'
    });
    setAuthStatus('Authenticated');
  };

  const handleLaunch = () => {
    AuthServicePKCE.login();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {user ? (
        <SpotifyApp user={user} />
      ) : (
        <>
          <PageHeader
            title="Spotify App"
            //description="Documentation and examples for how I think about Product Managment"
            className="mb-3"
          ></PageHeader>
          <Card>
            <Section
              className="py-0 overflow-hidden"
              data-bs-theme="light"
              //image={bg3}
              position="center bottom"
              overlay
            >
              <Row>
                <Col md={11} lg={8} xl={4} className="mt-1 mt-xl-0 pb-4">
                  <Card.Title className="mt-3">Getting Started</Card.Title>

                  <Card.Body>
                    <Card.Text>Welcome to my Spotify React App!</Card.Text>
                    <Card.Text>
                      This doc will guide you to understand how to access all
                      the features of the app that I've thrown together using
                      the Spotify Web API. Because my app is only in{' '}
                      <strong>Development Mode</strong>, you will need to be
                      added to my list of authorized users. Instructions for
                      doing so are below. Hope you enjoy!
                    </Card.Text>
                  </Card.Body>
                </Col>
                <Col xl={{ span: 7, offset: 1 }} className="mt-1 mt-xl-0">
                  <Card.Title className="mt-3 text-warning">
                    Already been given authorization?
                  </Card.Title>
                  <Card.Body>
                    <Card.Text>
                      Click the button below to launch my Spotify App using the
                      OAuth2 Authorization with PKCE extension flow.
                    </Card.Text>
                  </Card.Body>

                  <Button
                    disabled={authStatus !== 'New'}
                    onClick={handleLaunch}
                  >
                    Launch Spotify
                  </Button>
                </Col>
              </Row>
            </Section>
            <SpotifyInstructions />
          </Card>
        </>
      )}
    </>
  );
};

export default Spotify;
