import logoDuluth from 'assets/img/logos/duluth.png';

export default [
  {
    id: 1,
    logo: logoDuluth,
    institution: 'University of Minnesota Duluth',
    degree:
      'Management Information Systems <br />Bachelor of Business Administration',
    duration: '1999 - 2003',
    location: 'Duluth, MN',
    verified: true
  }
];
