import logoCHRobinson from 'assets/img/logos/CHRobinson.png';
import logoAimia from 'assets/img/logos/aimia.png';
import logoFirstmark from 'assets/img/logos/firstmark.png';
import logoIcfNext from 'assets/img/logos/icfNext.png';
import logoOptum from 'assets/img/logos/optum.png';
import logoRust from 'assets/img/logos/rust.png';
import logoSecurian from 'assets/img/logos/securian.png';
import logoSHPS from 'assets/img/logos/shps.png';

export default [
  {
    id: 1,
    logo: logoSecurian,
    title: 'Sr. Product Consultant',
    company: 'Securian Financial',
    startDate: 'Sep 2022',
    endDate: 'Dec 2023',
    duration: '1 years 4 months',
    location: 'St. Paul, MN',
    verified: true,
    url: 'http://www.securian.com'
  },
  {
    id: 2,
    logo: logoIcfNext,
    title: 'Director, Product Management',
    company: 'ICF Next',
    startDate: 'Jan 2015',
    endDate: 'Sep 2022',
    duration: '7 years 9 months',
    location: 'Minneapolis, MN',
    verified: true,
    url: 'https://www.icf.com/next'
  },
  {
    id: 3,
    logo: logoOptum,
    title: 'Techincal Product Manager',
    company: 'Optum',
    startDate: 'Mar 2012',
    endDate: 'Jan 2015',
    duration: '2 years 11 months',
    location: 'Minneapolis, MN',
    verified: true,
    url: 'https://www.optum.com/'
  },
  {
    id: 4,
    logo: logoRust,
    title: 'Systems Analyst',
    company: 'Rust Consulting',
    startDate: 'April 2011',
    endDate: 'March 2012',
    duration: '1 year',
    location: 'Minneapolis, MN',
    verified: true,
    url: 'https://www.rustconsulting.com/'
  },
  {
    id: 5,
    logo: logoCHRobinson,
    title: 'Business Analyst',
    company: 'C.H. Robinson',
    startDate: 'Jun 2010',
    endDate: 'Apr 2011',
    duration: '11 months',
    location: 'Eden Prairie, MN',
    verified: true,
    url: 'https://www.chrobinson.com/'
  },
  {
    id: 6,
    logo: logoAimia,
    title: 'Project Manager / Business Analyst',
    company: 'Aimia (formerly Carlson Marketing)',
    startDate: 'Nov 2006',
    endDate: 'Jun 2010',
    duration: '3 years 8 months',
    location: 'Minneapolis, MN',
    verified: true,
    url: 'https://www.aimia.com/'
  },
  {
    id: 7,
    logo: logoSHPS,
    title: 'Business Systems Analyst',
    company: 'SHPS, Inc. of Carewise Health (acquired by ADP)',
    startDate: 'Jan 2011',
    endDate: 'Apr 2012',
    duration: '1 yrs 4 mon',
    location: 'Minneapolis, MN',
    verified: true,
    url: 'https://www.carewisehealth.com/node/34'
  },
  {
    id: 8,
    logo: logoFirstmark,
    title: 'Business Analyst',
    company: 'Firstmark Services',
    startDate: 'Jul 2004',
    endDate: 'Mar 2005',
    duration: '9 months',
    location: 'Woodbury, MN',
    verified: true,
    url: 'https://firstmarkservices.com/'
  }
];
