import ActionButton from 'components/common/ActionButton';
import PageHeader from 'components/common/PageHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { EditUser } from './EditUser';

const UserAdminTable = props => {
  const [users, setUsers] = useState([]);
  const [searchFirstName, setSearchFirstName] = useState('');
  const [currentUser, setCurrentUser] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const { response: profilesResponse, fetchData } = useAxiosPrivate();
  const { error, fetchData: deleteData } = useAxiosPrivate();

  const usersRef = useRef();

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  useEffect(() => {
    retrieveUsers();
  }, []);

  useEffect(() => {
    setUsers(profilesResponse);
  }, [profilesResponse]);

  const onUserSearch = e => {
    const searchFirstName = e.target.value;
    setSearchFirstName(searchFirstName);
  };

  const retrieveUsers = async () => {
    await fetchData({
      url: 'profiles',
      method: 'get'
    });
  };

  const handleDelete = async (profileId, rowIdx) => {
    await deleteData({
      url: 'profiles/' + profileId,
      method: 'delete'
    }).then(() => {
      if (error) {
        console.log(error);
      } else {
        console.log('No error');
        const newUsers = [...usersRef.current];
        //console.log(newUsers);
        newUsers.splice(rowIdx, 1);
        console.log(newUsers);
        setUsers(newUsers);
      }
      console.log('Users', users);
    });
  };

  const findByFirstName = async event => {
    event.preventDefault();

    await fetchData({
      url: 'profiles?firstName=' + searchFirstName,
      method: 'get'
    });
  };

  const openUser = currentUser => {
    setCurrentUser(currentUser);
    setModalShow(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'profileId',
        disableSortBy: true
      },
      {
        Header: 'First Name',
        accessor: 'firstName'
      },
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'Email',
        accessor: 'username'
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        Cell: props => {
          const rowIdx = props.row.id;
          return (
            <>
              <ActionButton
                icon="edit"
                title="Edit"
                variant="action"
                className="p-0 me-2 fs-10"
                onClick={() => openUser(props.row.original)}
              />
              <ActionButton
                icon="trash-alt"
                title="Delete"
                variant="action"
                className="p-0 fs-10"
                onClick={() =>
                  handleDelete(props.row.original.profileId, rowIdx)
                }
              />
            </>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      <PageHeader title="User Admin" className="mb-3"></PageHeader>
      <Card>
        <Card.Body>
          <Form onSubmit={findByFirstName}>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Search by first name"
                  value={searchFirstName}
                  onChange={onUserSearch}
                />
              </Col>
              <Col>
                <Button
                  variant="primary"
                  className="me-2 mb-1"
                  type="submit"
                  //onClick={findByFirstName}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
          {users ? (
            <AdvanceTableWrapper
              columns={columns}
              data={users}
              pagination
              perPage={50}
            >
              <AdvanceTable
                table
                headerClassName="bg-200 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs-10 mb-0 overflow-hidden'
                }}
              />
            </AdvanceTableWrapper>
          ) : (
            <h3>No Users to Display</h3>
          )}
        </Card.Body>
      </Card>
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditUser
            user={currentUser}
            stuff={() => setModalShow(false)}
            reset={retrieveUsers}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserAdminTable;
