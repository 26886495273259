import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const EditUser = ({ user, stuff, reset, goBack }) => {
  const [currentUser, setCurrentUser] = useState(user);
  const navigate = useNavigate();
  const { response, loading, fetchData } = useAxiosPrivate();

  console.log('In the Edit User Component');
  console.log(user);

  const updateProfile = async (id, profile) => {
    await fetchData({
      url: 'profiles/' + id,
      method: 'put',
      data: profile
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateProfile(currentUser.profileId, currentUser)
      .then(response => {
        if (stuff) {
          stuff();
        }
        if (reset) {
          reset();
        }
        if (goBack) {
          navigate(-1);
        }
        console.log('Response', response);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="g-3 mb-3">
          <Form.Label column="sm" lg={2}>
            First Name
          </Form.Label>
          <Col>
            <Form.Control
              size="sm"
              type="text"
              id="firstName"
              name="firstName"
              value={currentUser.firstName}
              onChange={handleInputChange}
            />
          </Col>
          <Form.Label column="sm" lg={2}>
            Last Name
          </Form.Label>
          <Col>
            <Form.Control
              size="sm"
              type="text"
              id="lastName"
              name="lastName"
              value={currentUser.lastName}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row className="g-3  mb-3">
          <Form.Label column="sm" lg={2}>
            Email
          </Form.Label>
          <Col>
            <Form.Control
              size="sm"
              type="text"
              id="username"
              name="username"
              value={currentUser.username}
              onChange={handleInputChange}
            />
          </Col>
        </Row>

        <Button type="submit" variant="primary" className="me-2 mb-1">
          Update Everywhere
        </Button>
      </Form>
    </>
  );
};
