import { useAppContext } from 'Main';
import spotifyDemo from 'assets/img/spotify/spotifyDemo.png';
import spotifyDemoDark from 'assets/img/spotify/spotifyDemoDark.png';
import Section from 'components/common/Section';
import useAxios from 'hooks/useAxios';
import { empty } from 'is_js';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';

const SpotifyInstructions = ({ hasLabel }) => {
  const {
    config: { isDark }
  } = useAppContext();

  const form = useRef();
  const checkBtn = useRef();
  const { response, loading, fetchData } = useAxios();

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });

  const submitRequest = async (
    firstName,
    lastName,
    emailAddress,
    requestType
  ) => {
    await fetchData({
      url: '/requests',
      method: 'post',
      data: { firstName, lastName, emailAddress, requestType }
    });
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    const allErrors = {};

    // Validation
    if (!validator.isLength(formData.firstName, { min: 2, max: undefined })) {
      allErrors.firstName = 'Please supply a valid first name';
    }

    if (!validator.isLength(formData.lastName, { min: 2, max: undefined })) {
      allErrors.lastName = 'Please supply a valid last name';
    }

    if (!validator.isEmail(formData.email)) {
      allErrors.email = 'Please supply a valid email';
    }

    if (!empty(allErrors)) {
      setErrors(allErrors);
    } else {
      if (checkBtn.length != 0) {
        console.log(
          'Submitting request, need to implement email or add to table'
        );

        submitRequest(
          formData.firstName,
          formData.lastName,
          formData.email,
          'SPOTIFY_AUTH'
        ).then(() => {
          toast.success(`Requested access for: ${formData.email}`, {
            theme: 'colored'
          });
        });
      }
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Section
      className="py-0 overflow-hidden"
      data-bs-theme="light"
      //image={bg3}
      position="center bottom"
      overlay
    >
      <Row className="g-3 mb-3">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <Card.Title>Submit Request for Access</Card.Title>

          <Card.Body>
            <Card.Text>
              Because this is a personal project and I do not intend to make it
              widely available, I have set it up in{' '}
              <strong>Development Mode</strong>. Per Spotify quotas, this means
              that only up to 25 authorized users can access the app. If you
              would like to be added to the list of authorized users, please
              complete the form below.
            </Card.Text>
            <Card.Text>
              Requests are generally processed within 24 hours. You will receive
              an email confirmation once you have been added to the list of
              authorized users.
            </Card.Text>
          </Card.Body>
          <Form onSubmit={handleSubmit} ref={form}>
            <Row className="g-2 mb-3">
              <Form.Group as={Col} sm={6}>
                {hasLabel && <Form.Label>First Name</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'First Name' : ''}
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleFieldChange}
                  type="text"
                  isInvalid={!!errors.firstName}
                  feedback="Error"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                {hasLabel && <Form.Label>Last Name</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'Last Name' : ''}
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleFieldChange}
                  type="text"
                  isInvalid={!!errors.lastName}
                  feedback="Error"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group className="mb-3">
              {hasLabel && <Form.Label>Email address</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? 'Email address' : ''}
                value={formData.email}
                name="email"
                autoComplete="username"
                onChange={handleFieldChange}
                type="text"
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
              <Button
                className="w-100"
                type="submit"
                disabled={
                  !formData.firstName || !formData.lastName || !formData.email
                }
                ref={checkBtn}
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-end mt-1 mt-xl-0"
        >
          <Link to="/tech/spotify" className="img-landing-banner">
            <img
              className="img-fluid"
              src={isDark ? spotifyDemoDark : spotifyDemo}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

SpotifyInstructions.propTypes = {
  hasLabel: PropTypes.bool
};

export default SpotifyInstructions;
