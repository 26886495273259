import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const TechOverview = () => {
  return (
    <>
      <Row>
        <Col xs={2}></Col>
        <Col>
          <Card className="px-5">
            <Card.Header>
              <h1>Overview</h1>
            </Card.Header>
            <Card.Body className="px-4">
              <Card.Text>
                Welcome to my portfolio of projects! As a seasoned product
                manager, I've always been fascinated by the intricate dance
                between technology, user experience, and business strategy.
                Throughout my career, I've made it a priority to not only
                understand the products I work on but also the technologies that
                power them. This portfolio showcases a collection of personal
                projects where I've immersed myself in various technologies,
                honing my skills and deepening my understanding of their impact
                on product development.
              </Card.Text>
              <Card.Text>
                As you navigate through these pages, you'll discover how my
                exploration of technology has transformed my ability to
                communicate effectively with both customers and engineers alike.
                By gaining fluency in the languages of both technical jargon and
                user-centric language, I've become adept at bridging the gap
                between diverse stakeholders. Whether I'm distilling complex
                technical concepts into layman's terms for customers or
                translating user feedback into actionable insights for
                engineers, my proficiency in technology has enabled me to foster
                clearer lines of communication and facilitate more meaningful
                collaborations.
              </Card.Text>
              <Card.Text>
                Through these projects, I've delved into a variety of domains,
                from front-end development and data analytics to cloud computing
                and machine learning. Each endeavor has provided valuable
                opportunities to not only expand my technical skill set but also
                to cultivate empathy and respect for the expertise of my
                colleagues. By fostering a culture of collaboration and mutual
                understanding, I've helped create environments where everyone's
                contributions are valued, and innovative solutions can flourish.
              </Card.Text>
              <Card.Text>
                I invite you to explore these projects and witness firsthand how
                a deep understanding of technology can empower a product manager
                to communicate effectively, drive collaboration, and unlock new
                possibilities. Thank you for joining me on this journey, and I
                look forward to sharing my passion for technology-driven product
                management with you.
              </Card.Text>
            </Card.Body>
            <Card.Footer></Card.Footer>
          </Card>
        </Col>
        <Col xs={2}></Col>
      </Row>
    </>
  );
};

export default TechOverview;
