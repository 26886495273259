export const productRoutes = {
  label: 'Product Managment',
  children: [
    {
      name: 'Philosophy',
      active: true,
      to: '/product/philosophy'
    },
    /* {
      name: 'Portfolio',
      active: true,
      to: '/product/portfolio'
    }, */
    {
      name: 'Product Vision and Stragegy',
      active: true,
      //icon: 'chart-pie',
      to: '/product/vision'
    } /* ,
    {
      name: 'Product Objectives',
      active: true,
      //icon: 'chart-pie',
      to: '/product/objectives'
    },
    {
      name: 'Product Roadmap',
      active: true,
      //icon: 'chart-pie',
      to: '/product/roadmap'
    },
    {
      name: 'Product Metrics',
      active: true,
      //icon: 'chart-pie',
      to: '/product/metrics'
    },
    {
      name: 'Prioritization',
      active: true,
      //icon: 'chart-pie',
      to: '/product/prioritization'
    },
    {
      name: 'Backlog Management',
      active: true,
      //icon: 'chart-pie',
      to: '/product/backlog'
    } */
  ]
};
export const techRoutes = {
  label: 'Technology',
  children: [
    {
      name: 'Overview',
      //icon: 'calendar-alt',
      to: '/tech',
      active: true
    },
    {
      name: 'Spotify',
      //icon: 'comments',
      to: '/tech/spotify',
      active: true
    },
    {
      name: 'Shopping Cart',
      //icon: 'comments',
      to: '/tech/shoppingCart',
      active: true
    },
    {
      name: 'Jira',
      //icon: 'comments',
      to: '/tech/jira',
      active: true
    },
    {
      name: 'Role Based Access',
      //icon: 'comments',
      to: '/tech/roles',
      active: true
    },
    {
      name: 'User Config Table',
      active: true,
      icon: 'user',
      to: '/adminNew/userTable'
    } /* ,
    {
      name: 'AWS Hosting',
      //icon: 'comments',
      to: '/tech/hosting',
      active: true
    } */
  ]
};

export const blogRoutes = {
  label: 'Blog',
  to: '/blog',
  active: true,
  children: [
    {
      name: 'Overview',
      //icon: 'calendar-alt',
      to: '/tech/overview',
      active: true
    },
    {
      name: 'About',
      //icon: 'comments',
      to: '/about',
      active: true
    }
  ]
};

export default [productRoutes, techRoutes];
