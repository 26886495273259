import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditUser } from './EditUser';

const User = () => {
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(location.state.user);

  let navigate = useNavigate();

  console.log(currentUser);

  return (
    <div>
      <Card>
        <Card.Body>
          {currentUser ? (
            <div className="edit-form">
              <EditUser user={currentUser} goBack />
            </div>
          ) : (
            <div>
              <br />
              <p>Please click on a User...</p>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default User;
