import { useAppContext } from 'Main';
import bg3 from 'assets/img/generic/bg-3.jpg';
import dashboardDark from 'assets/img/generic/dashboard-alt-dark.png';
import dashboard from 'assets/img/generic/dashboard-alt.jpg';
import Section from 'components/common/Section';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';

const Hero = () => {
  const {
    config: { isDark, isRTL }
  } = useAppContext();

  return (
    <Section
      className="py-0 overflow-hidden"
      data-bs-theme="light"
      image={bg3}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <h1 className="text-white fw-light">
            Product thinking will bring{' '}
            <span className="fw-bold">
              <Typewriter
                words={[
                  'value',
                  'satisfaction',
                  'innovation',
                  'delight',
                  'simplicity'
                ]}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={50}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            to your customers
          </h1>
          <p className="lead text-white opacity-75">
            Product thinking revolutionizes business strategies by prioritizing
            customer-centricity, innovation, and competitiveness. It enables
            organizations to develop offerings that maximize value, efficiency,
            satisfaction, and convenience, driving growth and success in dynamic
            market environments.
          </p>
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-end mt-4 mt-xl-0"
        >
          <Link to="/" className="img-landing-banner">
            <img
              className="img-fluid"
              src={isDark ? dashboardDark : dashboard}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
