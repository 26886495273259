import React from 'react';

const JiraInstructions = () => {
  return (
    <div>
      <h3 className="text-white">Placeholder</h3>
    </div>
  );
};

export default JiraInstructions;
