import React from 'react';
import { Form } from 'react-bootstrap';

const Shopping = () => {
  return (
    <>
      <div>
        <main>
          <section id="filters">
            <Form.Label htmlFor="size">Filter by Size:</Form.Label>{' '}
            <Form.Select id="size">
              <option value="">All sizes</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </Form.Select>
          </section>
        </main>
      </div>
    </>
  );
};

export default Shopping;
