import { useAppContext } from 'Main';
import { useUser } from 'context/UserProvider';
import React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { productRoutes, techRoutes } from 'routes/publicMaps';
import { adminRoutes } from 'routes/siteMaps';
import NavbarDropdown from './NavbarDropdown';

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useAppContext();

  const { user } = useUser();

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN'); // Check if user and user.roles are defined before accessing includes()

  return (
    <>
      <NavbarDropdown title="product">
        {productRoutes.children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
      <NavbarDropdown title="technology">
        {techRoutes.children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>
      <Nav.Item>
        <Nav.Link as={Link} to="/blog">
          Blog
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/about">
          About
        </Nav.Link>
      </Nav.Item>
      {isAdmin ? (
        <NavbarDropdown title="admin">
          {adminRoutes.children.map(route => (
            <Dropdown.Item
              key={route.name}
              as={Link}
              className={route.active ? 'link-600' : 'text-500'}
              to={route.to}
              onClick={handleDropdownItemClick}
            >
              {route.name}
            </Dropdown.Item>
          ))}
        </NavbarDropdown>
      ) : null}
    </>
  );
};

export default NavbarTopDropDownMenus;
